// User
export const userProfile = '/user/profile';
export const userGetLoginTokenUrl = '/user/fbtoken';
export const uploadCompanyLogo = '/user/upload-company-logo';
export const uploadProfilePicture = '/user/upload-profile-pic';
export const userLogin = '/user/login';

// Freemium
export const getFreemiumAdvisorsEndpoint = '/v1/freemium/advisors';
export const getFreemiumCompaniesEndpoint = '/v1/freemium/companies';
export const updateFreemiumCompanyEndpoint =
	'/v1/freemium/companies/toggle-freemium';

// New Loan Inquiry
export const addNewLoanInquiryUrl = '/save-new-loan-inquiry';
export const getAdvisorLoanRequests = '/get-new-loan-inquiry';
export const getLoanRequestRelatedDocuments = '/new-loan-details/documents';
export const getLoanRequestDocDownloadUrl =
	'/new-loan-inquiry/download-document';

// Document Related
export const updateLoanDocumentStatusEndpoint =
	'/new-loan-inquiry/update-document-status';

// Loan Offer related
export const saveNewLoanOfferUrl = '/new-loan-details/save-offer';
export const updateLoanOfferUrl = '/new-loan-details/update-offer';
export const deleteLoanOfferUrl = '/new-loan-inquiry/delete-offer';

// Passwordless
export const getMagicToken = '/user/magictoken';
export const getCustomToken = '/user/customtoken';

// Alerts
export const alertSettingsUrl = '/alerts/settings';
export const alertSettingsSubscribeUrl = '/alerts/subscribe';
export const alertSettingsUnsubscribeUrl = '/alerts/unsubscribe';

// FA -> Financial Advisor Related
export const faClients = '/fa/clients';
export const faContactClient = '/fa/send-message';
export const faLiabilitySummary = '/fa/summary';

// Client
export const clientInvitation = '/client/invite';
export const reInviteClientUrl = '/client/re-invite';
export const verifyClientInvitation = '/client/verify?inviteCode=';
export const generateReport = '/client/get-query-report';

// Liability
export const commercialLoanEndpoint = '/biz/tradeline';

// Wallet page
export const walletLiabilityURL = '/v1/wallet/liability';
export const walletOverviewUrl = '/wallet/overview';
export const getWalletBurnDown = '/wallet/reports/burndown';
// These are the "rules engines from the BE"
export const getSoraInsightsUrl = '/rule/execute';

// Data Refresh
export const dataRefreshUrl = '/v1/data/refresh';

// Mask mode, impersonating another user account
export const maskAsAnotherUser = '/v1/info/super';

// Analytics
export const analyticsOfferUrl = '/analytics/offer';
export const analyticsLiabilityUrl = '/analytics/liability';

// Admin
export const getAdvisorsEndpoint = '/admin/v1/advisors';

// Used to count advisors (old endpoint)
export const getAdvisorsOnboardedCountEndpoint = '/v1/advisor/list';

// Miscellaneous
export const onboardUrl = '/onboard';
export const fileUploadUrl = '/file';
export const loanDocumentUploadUrl = '/new-loan-inquiry/upload-document';
export const deleteDocumentUrl = '/new-loan-inquiry/delete-document';
export const updateNewLoanInquiryStatusUrl = '/update-new-loan-inquiry-status';
export const newHomeUrl = '/newhome';
export const loanAppliedUrl = '/loanapplied';
export const estimatedValueUrl = '/estimatedvalue';
export const chatGptInsightsUrl = '/v1/gpt/rewrite-advice';
export const verifyEmployeeUrl = '/verifyemployee';

// Tasks
export const taskIsDoneUrl = '/external/contact/task/done';
export const loanTaskIsDoneUrl = '/new-loan-inquiry-task-done';

// Loan Requests
export const loanRequestUrl = '/new-loan-details';

// 3rd Party Below

// Methodfi
export const getMethodfiToken = '/mfi-connect';
export const getMethodfiLiabilities = '/mfi-liabilities/';

// PinWheel
export const pinwheelTokenUrl = '/token';
export const pinwheelAccountConnectUrl = '/pinwheel';
export const clientIncomeUrl = '/income/manualupload';

// TalkJs
export const postCreateTalkJsConversation = '/talkjs/create-conversations';
export const getTalkJsConversation = '/talkjs/conversations/id';

// WealthBox
export const wealthBoxAuthApi = 'https://app.crmworkspace.com/oauth/authorize';
export const wealthBoxSoraConnect = '/integration/wealthbox/connect/';
export const wealthboxGetContacts = '/external/contactlist';

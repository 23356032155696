import { useMutation } from 'react-query';

// talkJs Axios Instance
import axiosInstance from 'services/API/API';
import talkJSAxiosInstance from 'services/API/TalkJsAxiosInstance';

// Our Endpoints
import { postCreateTalkJsConversation } from 'shared/api-urls';

// Our consants
const SORA_GENERAL_CHANNEL_NAME = 'Sora General Channel';

function useMutateCreateGeneralChannel() {
	return useMutation(async (advisorId) => {
		const response = await axiosInstance.post(
			postCreateTalkJsConversation,
			{
				participants: [advisorId],
				subject: 'Sora General Channel',
				photoUrl:
					'https://prod-public-asset.s3.amazonaws.com/Sora-Profile.jpg'
			}
		);

		const conversationData = response.data.data;

		const { conversationId: generalChannelId, participants } =
			conversationData;

		const adminTalkJsId = participants.filter((participant) =>
			participant.includes('admin')
		)[0];

		const advisorTalkJsId = participants.filter(
			(participant) => !participant.includes('admin')
		)[0];

		const talkJsResponse = await talkJSAxiosInstance.get(
			`/users/${advisorTalkJsId}/conversations`
		);

		const advisorExistingConversations = talkJsResponse.data.data;

		const shouldSendWelcomeMessage = advisorExistingConversations.some(
			(conversation) =>
				conversation.subject === SORA_GENERAL_CHANNEL_NAME &&
				conversation.lastMessage === null
		);

		if (shouldSendWelcomeMessage) {
			await talkJSAxiosInstance.post(
				`/conversations/${generalChannelId}/messages`,
				[
					{
						text: `Welcome to the Sora Message Center. You can use this General channel to send secure inquiries to Sora. To request support for a particular client, select the client from the dropdown above and submit your inquiry. New notifications, alerts and messages related to a particular client will be displayed in that specific client channel. Your clients do not see any messages submitted through the Message Center.`,
						sender: adminTalkJsId,
						type: 'UserMessage'
					}
				]
			);
		}

		return generalChannelId;
	});
}

export default useMutateCreateGeneralChannel;

import { Grid } from '@mui/material';

// Our Components
import ClientSummaryCard from 'components/SoraWallet/Cards/ClientSummaryCard';
import TotalLiabilitiesTable from 'components/SoraWallet/Cards/TotalLiabilitiesTable';

// Our Constants
import { COMMERCIAL } from 'shared/constants';

function ClientSummaryWallet({ clientWalletData }) {
	const { email, firstName, lastName } = clientWalletData;

	const { bizLoans, liabilities, total } = clientWalletData.liability;

	const safeBizLoans = bizLoans ?? [];

	// This exists because the biz loan has different fields than the liabilities
	// Fields that the table wants such as tradeLineType, dataSource, monthlyPay
	const bizLoansWithCorrectedNames = safeBizLoans.map((bizLoan) => {
		const { id, lenderName, lastUpdated, monthlyPayment, rate } = bizLoan;

		return {
			...bizLoan,
			dataSource: 'Manual',
			interestRate: rate,
			lender: lenderName,
			lastUpdatedDate: lastUpdated,
			monthlyPay: monthlyPayment,
			rateManuallyUpdated: true,
			tradeLineType: COMMERCIAL,
			tradelineId: id
		};
	});

	const clientData = { email, firstName, lastName };

	return (
		<Grid item container xs={12}>
			<ClientSummaryCard clientWalletData={clientWalletData} />

			<Grid item xs={12} sx={{ overflowX: 'auto' }}>
				<TotalLiabilitiesTable
					bizLoans={bizLoansWithCorrectedNames}
					clientData={clientData}
					totalLiabilities={total}
					liabilities={liabilities}
				/>
			</Grid>
		</Grid>
	);
}

export default ClientSummaryWallet;

import { useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import BasicSwitch from 'components/Switches/BasicSwitch';
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import SearchClients from 'components/Input/Clients/SearchClients';
import SoraTooltip from 'components/Tooltip';

function ClientListTable({
	clients,
	setSelectedClient,
	setIsAccordionExpanded
}) {
	const [searchData, setSearchData] = useState('');

	const [showActiveClients, setShowActiveClients] = useState(true);

	const handleShowPendingClients = () => {
		setShowActiveClients(!showActiveClients);
	};

	const filteredClientsData = useMemo(
		() =>
			clients
				.filter((client) => {
					const isCurrentClientActive = client.status === 'ACTIVE';

					if (showActiveClients && isCurrentClientActive) return true;

					if (!showActiveClients && !isCurrentClientActive)
						return true;

					return false;
				})
				.map(
					({
						invitedEmail: email,
						invitedName: clientName,
						invitedAccepted,
						clientId,
						status,
						numberOfLoans
					}) => {
						const isCurrentClientPending = status === 'PENDING';

						if (!showActiveClients && isCurrentClientPending) {
							return {
								email,
								clientName,
								invitedAccepted,
								clientId,
								status,
								numberOfLoans,
								id: uuidv4()
							};
						}

						return {
							email,
							clientName,
							invitedAccepted,
							clientId,
							status,
							numberOfLoans,
							id: clientId
						};
					}
				)
				.filter((client) => {
					const fieldsOfInterest = [client.clientName, client.email];

					const hasFieldOfInterest = fieldsOfInterest.some(
						(fieldValue) =>
							fieldValue
								.toLowerCase()
								.includes(searchData.toLowerCase())
					);

					return hasFieldOfInterest;
				}),
		[clients, searchData, showActiveClients]
	);

	const clientNameKey = 'clientName';
	const emailKey = 'email';
	const invitedAcceptedKey = 'invitedAccepted';
	const numberOfLoansKey = 'numberOfLoans';
	const statusKey = 'status';
	const clientIdKey = 'clientId';

	const columnsClient = [
		ColumnItem(clientNameKey, 'Client Name', 0.5, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			initialSortDirection: 'desc'
		}),
		ColumnItem(emailKey, 'Email', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(statusKey, 'Status', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(clientIdKey, 'Client ID', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(invitedAcceptedKey, 'Invite Accepted', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(numberOfLoansKey, 'Loans', 0.15, {
			valueFormatter: (params) => {
				const numberOfLoans = params.value;

				if (numberOfLoans || numberOfLoans === 0) return numberOfLoans;

				return 'NA';
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		})
	];

	return (
		<>
			<Grid
				container
				sx={{
					justifyContent: 'space-between',
					marginBottom: 2
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Typography variant="body1" sx={{ display: 'block' }}>
						Select a client below to view their data
						<SoraTooltip
							text={
								showActiveClients
									? 'Active clients are shown by default. Active clients are clients that have accepted the invite and have completed onboarding.'
									: 'Non-active clients are clients that have not completed onboarding.'
							}
							toolTipSx={{ fontSize: 18 }}
						></SoraTooltip>
					</Typography>
					<BasicSwitch
						label={
							showActiveClients
								? 'Show non-active Clients'
								: 'Show active Clients'
						}
						labelPlacement="end"
						checked={!showActiveClients}
						onChange={handleShowPendingClients}
					/>
				</Box>

				<Grid item xs={12} xl={3}>
					<SearchClients
						debounceCallBack={setSearchData}
						sx={{ marginBottom: 2, width: '100%' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<DataTable
					columns={columnsClient}
					rows={filteredClientsData}
					rowsPerPageOptions={10}
					handleRowClick={(row) => {
						const selectedClient = row;
						const { status } = selectedClient;

						if (status !== 'PENDING' && status !== 'ONBOARDED') {
							setSelectedClient(row);
							setIsAccordionExpanded(false);
						}
					}}
					withRowColorCheck
				/>
			</Grid>
		</>
	);
}

export default ClientListTable;

import { Navigate } from 'react-router-dom';

// Our Components
import Loader from 'components/Loader';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Expected
function ProtectedRoute({ element }) {
	const { isSuccess, isError, isLoading } = useGetProfile();

	if (isLoading) return <Loader />;

	if (isError) {
		return <Navigate to="/login" />;
	}

	if (isSuccess) {
		return element;
	}

	return element;
}

export default ProtectedRoute;

import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useNavigate, generatePath } from 'react-router-dom';
import { useEffect, useRef } from 'react';

// Our Components
import DataTableToolBar from 'components/DataTable/DataTableToolBar';
import DataTableSortingArrow from 'components/DataTable/DataTableSortingArrow';
import OnboardingTasksNoRowOverlay from 'components/DataTable/OnboardingTasksNoRowOverlay';
import NoRowsOverlay from 'components/DataTable/NoRowsOverlay';
import NoLoanTasksOverlay from 'components/DataTable/NoLoanTasksOverlay';

// Our routes
import { ADVISOR_WALLET_ROUTE } from 'routes';

// Our utils
import { dataIsValid } from 'shared/utils';

const CustomArrowDropDownIcon = styled(ArrowDropDownIcon)`
	color: #000000 !important;
`;

const CustomArrowDropUpIcon = styled(ArrowDropUpIcon)`
	color: #000000 !important;
`;

// TODO @Daniel add Prop Types to specify the shape of columns, and rows
function DataTable({
	columns,
	disableSelectionOnClick,
	enableToolbar,
	enableScrollToBottom,
	handleRowClick,
	noLoanTasks,
	pageSize,
	rowHeight,
	rows,
	rowsPerPageOptions,
	sx,
	withOnboardNowOverlay,
	withRowColorCheck
}) {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	const invisibleRef = useRef(null);

	useEffect(() => {
		if (enableScrollToBottom && invisibleRef.current) {
			setTimeout(() => {
				invisibleRef.current.scrollIntoView({
					behavior: 'smooth'
				});
			}, 250);
		}
	}, []);

	const setClientIdAndNavigate = (clientId) => {
		const soraClientUrl = generatePath(
			`${ADVISOR_WALLET_ROUTE}${clientId}`,
			{
				clientId
			}
		);
		navigate(soraClientUrl);
	};

	const setNoLoanState = () => {
		if (withOnboardNowOverlay && !noLoanTasks) {
			return OnboardingTasksNoRowOverlay;
		}
		if (noLoanTasks) {
			return NoLoanTasksOverlay;
		}
		return NoRowsOverlay;
	};

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					autoPageSize
					autoHeight
					rows={rows}
					disableSelectionOnClick={disableSelectionOnClick ?? false}
					columns={columns}
					pageSize={pageSize} // example is 5
					rowsPerPageOptions={[rowsPerPageOptions]}
					rowHeight={rowHeight}
					headerHeight={48}
					onRowClick={(rowData) => {
						const { row } = rowData;
						if (handleRowClick) {
							handleRowClick(row);
						} else if ('status' in row) {
							if (row.status === 'ACTIVE') {
								const { clientId } = row;
								setClientIdAndNavigate(clientId);
							}
						} else {
							const { clientId } = row;
							setClientIdAndNavigate(clientId);
						}
					}}
					getRowClassName={(params) => {
						if (withRowColorCheck) {
							const hasStatusDataInRow = dataIsValid(
								params.row?.status
							);

							if (
								hasStatusDataInRow &&
								params?.row.status !== 'ACTIVE'
							)
								return 'MuiDataGrid-row-special';
						}

						return 'MuiDataGrid-row';
					}}
					sx={{
						...sx,
						backgroundColor:
							SoraTheme.palette.gradient
								.soraBlueToWhiteLinearGradient,
						'& .MuiDataGrid-columnSeparator': {
							display: 'none'
						},
						'& .MuiDataGrid-row': {
							cursor: 'pointer'
						},
						'& .MuiDataGrid-row-special': {
							backgroundColor: `${SoraTheme.palette.warning.main}`,
							'&:hover': {
								backgroundColor: `${SoraTheme.palette.warning.mainHover}`,
								cursor: 'not-allowed'
							},
							'& .Mui-selected': {
								backgroundColor: `${SoraTheme.palette.warning.main}`,
								'&:hover': {
									backgroundColor: `${SoraTheme.palette.warning.mainHover}`
								}
							}
						},
						'& .MuiDataGrid-columnHeader': {
							paddingLeft: 0
						},
						'& .MuiDataGrid-cell': {
							paddingLeft: 0
						},
						'& .MuiDataGrid-columnHeaderTitleContainer': {
							paddingTop: '8px',
							paddingBottom: '8px'
						},
						'& .MuiDataGrid-columnHeader:focus': {
							outline: 'none'
						},
						'& .MuiDataGrid-columnHeader:focus-within': {
							outline: 'none'
						},
						'& .MuiDataGrid-cell:focus': {
							outline: 'none'
						},
						'.MuiDataGrid-iconButtonContainer': {
							visibility: 'visible'
						},
						'.MuiDataGrid-sortIcon': {
							opacity: 'inherit'
						},
						border: 'none'
					}}
					components={{
						ColumnSortedAscendingIcon: CustomArrowDropDownIcon,
						ColumnSortedDescendingIcon: CustomArrowDropUpIcon,
						ColumnUnsortedIcon: DataTableSortingArrow,
						Toolbar: enableToolbar ? DataTableToolBar : null,
						NoRowsOverlay: setNoLoanState()
					}}
				/>
			</Box>

			<p ref={invisibleRef} style={{ visibility: 'hidden', height: 2 }}>
				invisible
			</p>
		</>
	);
}

const columnItemShape = {
	field: PropTypes.string.isRequired,
	headerName: PropTypes.string.isRequired,
	flex: PropTypes.number,
	props: PropTypes.object // props specifies mx props check component ColumnItem for links.
};

// Rows shape will be dependant on the columnItemShape.
DataTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape(columnItemShape)).isRequired,
	disableSelectionOnClick: PropTypes.bool,
	enableToolbar: PropTypes.bool,
	enableScrollToBottom: PropTypes.bool,
	pageSize: PropTypes.number,
	rowsPerPageOptions: PropTypes.number,
	rowHeight: PropTypes.number,
	withOnboardNowOverlay: PropTypes.bool,
	noLoanTasks: PropTypes.bool
};

DataTable.defaultProps = {
	disableSelectionOnClick: false,
	enableToolbar: true,
	enableScrollToBottom: false,
	pageSize: 10,
	rowsPerPageOptions: 10,
	rowHeight: 52, // Default by MUI I.e. What we had
	withOnboardNowOverlay: false,
	noLoanTasks: false
};

export default DataTable;
